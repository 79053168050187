import React, { useState, useRef } from 'react';

const CustomForm = ({ status, message, onValidated }) => {
    const [email, setEmail] = useState('');
    const [celular, setCelular] = useState('');
    const emailRef = useRef(null);
    const [aviso, setAviso] = useState(false);
    const [response, setResponse] = useState('');

    const handleSubmit = () => {
        if (email && email.includes('@')) {
            onValidated({
                EMAIL: email,
            });
            handleRegularSubmit();

            setAviso(false);
        } else {

            setAviso(true);
        }
    };

    const handleRegularSubmit = async () => {

        try {
            const response = await fetch('https://jacquesgomes.com.br/gb/salvar-assinante', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ celular, email })
            });

            if (!response.ok) {
                throw new Error('Erro ao enviar número de telefone.');
            }

        } catch (error) {
            console.log(error.message);
        }
    };

    return (
        <>

            <div className='newsletter-container'>
                <p>Inscreva-se em nossa newsletter</p>
                <span className='newsletter-feedback'>
                    {status === 'sending' && (
                        <span style={{ color: 'white' }}>Enviado...</span>
                    )}
                    {status === 'error' && (
                        <span
                            style={{ color: 'red' }}
                            dangerouslySetInnerHTML={{ __html: message }}
                        />
                    )}
                    {status === 'success' && (
                        <span
                            style={{ color: 'green' }}
                            dangerouslySetInnerHTML={{ __html: message }}
                        />
                    )}
                    {aviso && <p style={{ color: 'red' }}>Por favor, insira um email válido</p>}
                </span>
                <input
                    ref={emailRef}
                    type='email'
                    placeholder='Seu Email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <input
                    type='text'
                    placeholder='Seu celular (opcional)'
                    value={celular}
                    onChange={(e) => setCelular(e.target.value)}
                />
                    <button onClick={handleSubmit} className='main-btn'>
                        Inscrever-se
                    </button>

            </div>

        </>
    );
};

export default CustomForm;

