import React from "react";
import "./App.css";
import Links from "./components/shared/Footer";

import './assets/styles/Reset.css';

function App() {
    const main = process.env.REACT_APP_MAIN;

    return (
            <div className="App">
                    <Links />
            </div>
    );
}

export default App;