import React from 'react';

function LinkButton({ href, text, icon }) {
    return (
        <a
            href={href}
            target="_blank"
            rel="noreferrer"
        >
            <span><i className={icon}></i></span>
            <span>{text}</span>
        </a>
    );
}

export default LinkButton;
