import "./Footer.css";
import "./Detalhes.css";
import React from "react";
import Logo from "../../assets/imgs/logo cortada.png";
import Demo from "../newsletter/Demo";
import LinkButton from "./LinkButton";

const Footer = () => {
  return (
    <footer className="footer-background">
      <div className="footer-container header-body-footer-width">
        <div className="footer-logo">
          <img className="footer-img" src={Logo} alt="logo"></img>
          <span className="line"></span>
          <h2>Gabriel Bulhões</h2>
          <p className="subtitle">
            Advocacia Criminal e Investigação Defensiva{" "}
          </p>
          {/* <p>
            Oferecemos aos clientes um padrão de profissionalismo em uma
            advocacia artesanal
          </p> */}

          <span className="footer-socials">
            <a
              href="
            https://gabrielbulhoes.com.br/"
              target="_blank"
              aria-label="site principal do escritório"
              rel="noreferrer"
            >
              <i class="uil uil-link-h"></i>
            </a>

            <a
              href="https://wa.me/message/IMG5YFUHUYM5D1"
              aria-label="Whatsapp Icon"
              target="_blank"
              rel="noreferrer"
            >
              <i className="uil uil-whatsapp"></i>
            </a>

            <a
              href="https://www.facebook.com/gbadvocaciacriminal"
              target="_blank"
              rel="noreferrer"
              aria-label="site principal do escritório"
            >
              <i className="uil uil-facebook-f"></i>
            </a>
            <a
              href="https://www.linkedin.com/company/gb-advocacia-criminal/"
              target="_blank"
              rel="noreferrer"
              aria-label="site principal do escritório"
            >
              <i className="uil uil-linkedin-alt"></i>
            </a>
            <a
              href="https://www.instagram.com/gbadvocaciacriminal/"
              target="_blank"
              aria-label="site principal do escritório"
              rel="noreferrer"
            >
              <i className="uil uil-instagram"></i>
            </a>
            
            <a
              href="
            https://www.youtube.com/@GabrielBulhoes"
              target="_blank"
              aria-label="site principal do escritório"
              rel="noreferrer"
            >
              <i class="uil uil-youtube"></i>
            </a>

          </span>
        </div>

        <span class="linktree-content">
          <h3>Últimas novidades</h3>

          <LinkButton
            href="https://www.amazon.com.br/Manual-Pr%C3%A1tico-Investiga%C3%A7%C3%A3o-Defensiva-brasileira-ebook/dp/B0C8XMFS21"
            text="Livro: Manual Prático de Investigação Defensiva - 2a Ed."
            icon="uil uil-shopping-bag"
          />
          <LinkButton
            href="https://desastremaceio.gabrielbulhoes.com.br/"
            text="Conheça a Atuação do Escritório no Desastre de Macéio"
            icon="uil uil-link-h"
          />
          <LinkButton
            href="https://ebook-manual-pratico-de-id.gabrielbulhoes.com.br/"
            text="Download Gratuito: Manual Prático de Investigação Defensiva - 1a Ed."
            icon="uil uil-link-h"
          />
          <LinkButton
            href="https://ebook-codigo-deontologico-de-id.gabrielbulhoes.com.br/"
            text="Download Gratuito: Código Deontológico de Boas Práticas da Investigação Defensiva"
            icon="uil uil-link-h"
          />

          <h3>Entre em contato agora!</h3>
          <LinkButton
            href="https://wa.me/message/IMG5YFUHUYM5D1"
            text="WhatsApp 24h"
            icon="uil uil-whatsapp"
          />
          <LinkButton
            href="https://gabrielbulhoes.com.br/"
            text="Site do escritório"
            icon="uil uil-link-h"
          />
          <LinkButton
            href="https://maps.app.goo.gl/K1PRvxyESVEWAgf77"
            text="Como Chegar"
            icon="uil uil-location-point"
          />
          <LinkButton
            href="mailto:contato@gabrielbulhoes.com.br"
            text="Email"
            icon="uil uil-envelope"
          />

          <h3>Artigos Recentes</h3>

          <LinkButton
            href="https://gabrielbulhoes.com.br/reflexoes-sobre-a-improbidade-administrativa-no-ambito-do-processo-administrativo-disciplinar/"
            text="Reflexões sobre a Improbidade Administrativa no âmbito do Processo Administrativo Disciplinar"
            icon="uil uil-file-alt"
          />
          <LinkButton
            href="https://gabrielbulhoes.com.br/ia-e-o-direito-o-exemplo-do-gpsmed-e-as-possibilidades-da-tecnologia-na-atividade-juridica/"
            text="IA e o Direito: Possibilidades da tecnologia na atividade jurídica."
            icon="uil uil-file-alt"
          />
          <LinkButton
            href="https://gabrielbulhoes.com.br/defesa-na-era-digital-a-importancia-da-disponibilizacao-integral-dos-vestigios-obtidos-pelo-mp/"
            text="Defesa na era digital: a importância da disponibilização integral dos vestígios obtidos pelo MP."
            icon="uil uil-file-alt"
          />
          <LinkButton
            href="https://gabrielbulhoes.com.br/busca-e-apreensao-como-softwares-gratuitos-podem-auxiliar-na-demonstracao-da-ilicitude-da-diligencia-policial/"
            text="Busca e apreensão: como softwares gratuitos podem auxiliar na demonstração da (i)licitude da diligência policial."
            icon="uil uil-file-alt"
          />
        </span>

        <Demo />
        <span className="linktree-content">
          <h3>Prêmio análise DNA+FENALAW</h3>

          <LinkButton
            href="https://analise.com/noticias/banca-de-rio-grande-do-norte-recebe-premio-de-tecnologia"
            text="Banca do Rio Grande do Norte recebe prêmio de tecnologia"
            icon="uil uil-newspaper"
          />
          <LinkButton
            href="https://open.spotify.com/episode/2eowwObjEAGoa9sPHhCPUg?si=9Yy6ymgnQba_t4FZm6rVEw&amp;utm_medium=share&amp;utm_source=linktree&amp;nd=1"
            text="FenalawCast - Ep. 23: Gabriel Bulhões Advocacia Criminal e Investigação Defensiva: vencedor do Prêmio Análise DNA+ Fenalaw de Tecnologia"
            icon="uil uil-microphone"
          />
          <LinkButton
            href="https://www.youtube.com/watch?v=QIinHmM8zlg"
            text="Ganhadores das categorias de tecnologia e inovação Prêmio Análise DNA+FENALAW"
            icon="uil uil-play-circle"
          />
          <LinkButton
            href="https://jurinews.com.br/advocacia/gabriel-bulhoes-advocacia-criminal-recebe-premio-nacional-de-tecnologia-em-maior-evento-juridico-da-america-latina/"
            text="Gabriel Bulhões Advocacia Criminal recebe prêmio nacional de tecnologia em maior evento jurídico da América Latina - JuriNews"
            icon="uil uil-newspaper"
          />
          <LinkButton
            href="https://agorarn.com.br/ultimas/advogado-conquista-premio-em-evento-juridico-da-america-latina/"
            text="Advogado conquista prêmio em evento jurídico da América Latina"
            icon="uil uil-newspaper"
          />

          <h3>Mídia</h3>

          <LinkButton
            href="https://www.bbc.com/portuguese/articles/cn4rjlllyjvo"
            text="BBC Brasil: 'Facções nunca dormem': a guerra silenciosa por trás de ataques no Rio Grande do Norte - BBC News Brasil"
            icon="uil uil-newspaper"
          />
          <LinkButton
            href="https://agorarn.com.br/ultimas/referencia-no-brasil-advogado-criminalista-atua-em-14-estados-e-df/"
            text="AgoraRN: Referência no Brasil, advogado criminalista atua em 14 estados e DF"
            icon="uil uil-newspaper"
          />
          <LinkButton
            href="https://agorarn.com.br/ultimas/advogado-amplia-debate-com-2a-edicao-de-livro-sobre-investigacao-defensiva/"
            text="AgoraRN: Advogado amplia debate com 2ª edição de livro sobre investigação defensiva"
            icon="uil uil-newspaper"
          />
          <LinkButton
            href="https://agorarn.com.br/ultimas/brasil-e-o-12o-pais-com-mais-vazamentos-de-dados-de-usuarios/"
            text="AgoraRN: Brasil é o 12º país com mais vazamentos de dados de usuários"
            icon="uil uil-newspaper"
          />
          <LinkButton
            href="https://agorarn.com.br/ultimas/apos-promessa-de-rentabilidade-servidora-pede-emprestimo-e-fica-com-divida-de-r-50-mil/"
            text="AgoraRN: Após promessa de rentabilidade, servidora pede empréstimo e fica com dívida de R$ 50 mil"
            icon="uil uil-newspaper"
          />
          <LinkButton
            href="https://agorarn.com.br/ultimas/advogado-potiguar-critica-resolucao-que-restringia-uso-terapeutico-de-canabidiol/"
            text="AgoraRN: Advogado potiguar critica Resolução que restringia uso terapêutico de canabidiol"
            icon="uil uil-newspaper"
          />
          <LinkButton
            href="https://agorarn.com.br/ultimas/facilidades-que-o-cidadao-tem-o-crime-tambem-tem-diz-advogado-sobre-migracao-do-crime-organizado-ao-digital/"
            text="AgoraRN: 'Facilidades que o cidadão tem, o crime também tem', diz advogado sobre migração do crime organizado ao digital"
            icon="uil uil-newspaper"
          />

          <LinkButton
            href="https://www.youtube.com/watch?v=QW6LgJ_64g8"
            text="GLOBO (entrevista): 'OAB regulamenta a investigação defensiva'"
            icon="uil uil-play-circle"
          />
          <LinkButton
            href="https://uplexis.com.br/blog/artigos/fenalaw-digital-week-10-palestras-que-voce-nao-pode-perder/"
            text="FENALAW 2021: '10 palestras que você não pode perder na Fenalaw Digital Week'"
            icon="uil uil-link-h"
          />
          <LinkButton
            href="https://www1.folha.uol.com.br/poder/2021/05/datafolha-pesquisa-mostra-alcance-e-opiniao-da-advocacia-sobre-uso-de-tecnologia-na-profissao.shtml"
            text="FOLHA DE SÃO PAULO: 'Datafolha: pesquisa mostra alcance e opinião da advocacia sobre uso de tecnologia na profissão'"
            icon="uil uil-newspaper"
          />
          <LinkButton
            href="https://www.youtube.com/watch?v=Fxcbym4ydfo"
            text="PALESTRA COMPLETA: III Congresso Brasileiro de Direito Penal"
            icon="uil uil-play-circle"
          />
          <LinkButton
            href="https://www.youtube.com/watch?time_continue=16&amp;v=Q3B_vWUuAiU&amp;feature=emb_title"
            text="TV ESTADO DE DIREITO: Papo sobre Investigação Defensiva com Dora Cavalcanti e Gabriel Bulhões"
            icon="uil uil-play-circle"
          />
          <LinkButton
            href="https://www.youtube.com/watch?v=p3DygwNIbVo"
            text="GLOBO (entrevista): 'Investigação de pessoas que já se encontram presas'"
            icon="uil uil-play-circle"
          />
          <LinkButton
            href="https://www.youtube.com/watch?v=vqunVQbRdYg"
            text="GLOBO (entrevista): 'Absolvição de PM que matou e confessou'"
            icon="uil uil-play-circle"
          />

          <LinkButton
            href="https://www.youtube.com/watch?v=gr84ZPMCfDU"
            text="PALESTRA COMPLETA (IBCCRIM/SP): Investigação Defensiva"
            icon="uil uil-play-circle"
          />
        </span>
      </div>
    </footer>
  );
};

export default Footer;
